@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
      font-family: 'Rosterine';
      font-style: normal;
      font-weight: 400;
      font-display: swap;
      src: url(../public/rost.otf) format('otf');
    }
  }
  